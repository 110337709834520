import React from "react";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import PlanUnsubscribe from "../subscription/files/PlanUnsubscribe";

interface UserProfile {
  name: string;
  profile?: string;
}

interface Plan {
  name: string;
  amount: number;
  originalAmount: number;
  validityCount: string;
  validity: number;
  is_trial: boolean;
  isUnsubscriped : boolean;
  plan_start_date: string;
  plan_end_date: string;
  plantype?: {
    planTypeName: string;
  };
}

interface HeaderProps {
  userProfile?: UserProfile | null; // Allow both undefined and null
  walletBalance?: number;
  subscribedActivePlan?: Plan;
}

const Header: React.FC<HeaderProps> = ({
  userProfile,
  walletBalance,
  subscribedActivePlan,
}) => {
  const navigate = useNavigate();

  const planType = subscribedActivePlan?.plantype?.planTypeName;
  const planAmount = subscribedActivePlan?.originalAmount;
  console.log(
    "*********************************************************************"
  );
  console.log("planAmount", planAmount);
  console.log("subscribedActivePlan header", subscribedActivePlan);
  console.log(
    "subscribedActivePlan header subscribedActivePlan.validityCount",
    subscribedActivePlan?.validity
  );
  console.log(
    "*********************************************************************"
  );
  return (
    <section className="head row top-header align-items-center">
      {/* Logo and Welcome Message in the Same Row */}
      <div className="col-12 col-md-6 d-flex align-items-center">
        {/* Logo */}
        <div className="nav-brand me-3">
          <img
            className="img-fluid"
            src="../assets/images/user-logo.png"
            alt="Logo"
            style={{ height: "70px" }}
          />
        </div>
        {/* Welcome Message */}
        <h1 className="fs-6 fs-md-4 fw-bold text-success mb-0 justify-content-start">
          Welcome to Plan Subscription
        </h1>
      </div>

      {/* Plan Name, Profile & Dropdown */}
      {subscribedActivePlan &&(
        <div className="col-12 col-md-6 d-flex justify-content-between justify-content-md-center align-items-center mt-3 mt-md-0">
        {/* Plan Name */}
        <div className="plan-name">
          <span
            className="badge bg-primary me-md-5 mb-2 mb-md-0"
            style={{ fontSize: "1rem", padding: "10px 20px" }}
          >
            {subscribedActivePlan?.name || "No Active Plan"}
            {planType !== "Free Plan" &&
              subscribedActivePlan?.validity &&
              ` (${
                subscribedActivePlan?.is_trial
                  ? "Trial"
                  : subscribedActivePlan?.validity
              })`}
          </span>
        </div>

        {/* Avatar/Profile Dropdown */}
        {userProfile && (  <div className="dropdown profile-dd d-flex align-items-center">
          <button
            className="btn dropdown-toggle d-flex align-items-center"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {/* Avatar */}
            {userProfile?.profile ? (
              <Avatar
                src={userProfile.profile}
                name={userProfile.name}
                maxInitials={1}
                size="30"
                round
              />
            ) : (
              <Avatar
                name={userProfile?.name}
                maxInitials={1}
                size="30"
                round
                color="#607458"
                fgColor="#ffffff"
              />
            )}
            {/* Name */}
            <span className="px-2 mb-0 d-inline-block">
              {userProfile?.name}
            </span>
          </button>
          <ul
            className="dropdown-menu header-dropdown text-start"
            aria-labelledby="dropdownMenuButton1"
          >
            <li className="dropdown-item">
              <strong>Wallet Balance:</strong> $
              {walletBalance?.toFixed(2) || "0.00"}
            </li>
            <>{ console.log("subscribedActivePlan", subscribedActivePlan)}</>
             
            <li className="dropdown-item mt-2">
              <strong>Plan:</strong>{" "}
              {subscribedActivePlan?.name || "No Active Plan"}{" "}
              {planType !== "Free Plan" &&
                subscribedActivePlan?.validity &&
                ` (${
                  subscribedActivePlan?.is_trial
                    ? "Trial"
                    : subscribedActivePlan?.validity
                })`}
            </li>
            {planType !== "Free Plan" && planAmount !== 0 && !subscribedActivePlan?.is_trial && !subscribedActivePlan?.isUnsubscriped  &&(
              <li className="dropdown-item mt-2">
                <PlanUnsubscribe />
              </li>
            )}
          </ul>
        </div>)}
      </div>)}
    </section>
  );
};

export default Header;
