// index.tsx
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/app/assets/style.css";
import "../src/app/assets/style.css.map";
import "../src/app/assets/style.scss";
import "../src/app/assets/variables.css";
import "../src/app/assets/variables.css.map";
import "../src/app/assets/variables.scss";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./app/routings/AppRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { SplashScreen } from "./app/features/SplashScreen";
import PlanProvider from "./app/modules/subscription/files/PlanContext";
import { ModalProvider } from "./app/modules/Context/ModalContext";
import { SubscriptionProvider } from "./app/modules/Context/SubscriptionContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App: React.FC = () => {
  return (
    <Suspense fallback={<SplashScreen />}>
      <SubscriptionProvider>
        <PlanProvider>
          <ModalProvider>
            <AppRoutes />
          </ModalProvider>
          <ToastContainer />
        </PlanProvider>
      </SubscriptionProvider>
    </Suspense>
  );
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
