import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

interface PaymentMethodModalProps {
  show: boolean;
  onClose: () => void;
  offer: boolean;
  offerAmount: number;
  selectedPlanOption: Record<string, { amount: number } | null>;
  activePlanDetails: { id: string; button?: string };
  paymentType: number;
  setPaymentType: (type: number) => void;
  walletBalance: number;
  walletInputAmount: number;
  setWalletInputAmount: (amount: number) => void;
  handleSubscribe: (planId: string) => void;
}

const PaymentMethodModal: React.FC<PaymentMethodModalProps> = ({
  show,
  onClose,
  offer,
  offerAmount,
  selectedPlanOption,
  activePlanDetails,
  paymentType,
  setPaymentType,
  walletBalance,
  walletInputAmount,
  setWalletInputAmount,
  handleSubscribe,
}) => {

    useEffect(()=>{

    })
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Choose Your Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center text-danger mt-3">
          Select a payment method to proceed: $
          {offer ? (
            <>
              <span style={{ textDecoration: "line-through", color: "gray" }}>
                {(selectedPlanOption[activePlanDetails?.id || ""]?.amount || 0).toFixed(2)}
              </span>{" "}
              <span className="fw-bold">${offerAmount}</span>{" "}
            </>
          ) : (
            (selectedPlanOption[activePlanDetails?.id || ""]?.amount || 0).toFixed(2)
          )}
        </p>

        <div className="row mt-3">
          <div className="col-12 col-md-6 mb-3">
            <div className="form-check d-flex align-items-center">
              <label className="form-check-label d-flex align-items-center w-100">
                <input
                  className="form-check-input me-3"
                  type="radio"
                  name="paymentType"
                  value="1"
                  checked={paymentType === 1}
                  onChange={() => setPaymentType(1)}
                />
                <div className="d-flex flex-column">
                  <span className="d-flex align-items-center fw-bold">
                    <span className="material-symbols-outlined me-2" style={{ color: paymentType === 1 ? "#007bff" : "gray" }}>
                      account_balance_wallet
                    </span>
                    Wallet Balance
                  </span>
                  <small className="text-muted">Use your available balance (${(walletBalance ?? 0).toFixed(2)})</small>
                </div>
              </label>
            </div>
            {paymentType === 1 && (
              <div className="mt-3 mb-2">
                <label>Enter Amount to Deduct:</label>
                <input
                  type="number"
                  className="form-control"
                  value={walletInputAmount}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    const maxAmount = Number(Math.min(walletBalance, selectedPlanOption[activePlanDetails?.id || ""]?.amount || 0).toFixed(2));
                    setWalletInputAmount(value > maxAmount ? maxAmount : value);
                  }}
                  placeholder={`Enter up to ${Math.min(walletBalance, selectedPlanOption[activePlanDetails?.id || ""]?.amount || 0).toFixed(2)}`}
                />
              </div>
            )}
          </div>

          <div className="col-12 col-md-6 mb-3">
            <div className="form-check d-flex align-items-center">
              <label className="form-check-label d-flex align-items-center w-100">
                <input
                  className="form-check-input me-3"
                  type="radio"
                  name="paymentType"
                  value="2"
                  checked={paymentType === 2}
                  onChange={() => setPaymentType(2)}
                />
                <div className="d-flex flex-column">
                  <span className="d-flex align-items-center fw-bold">
                    <span className="material-symbols-outlined me-2" style={{ color: paymentType === 2 ? "#007bff" : "gray" }}>
                      credit_card
                    </span>
                    Pay by Card
                  </span>
                  <small className="text-muted">Pay using your credit/debit card</small>
                </div>
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-center">
        <button className="btn btn-cancel rounded-5 px-5 mt-2 mb-2" onClick={onClose}>
          Cancel
        </button>
        <button
          className="btn btn-primary rounded-5 px-5 mt-2 mb-2"
          onClick={() => handleSubscribe(activePlanDetails.id)}
          disabled={
            paymentType === 1
              ? walletInputAmount <= 0 ||
                walletInputAmount > (selectedPlanOption[activePlanDetails?.id || ""]?.amount || 0) ||
                walletInputAmount > walletBalance
              : paymentType !== 2
          }
        >
          Confirm {walletInputAmount > 0 ? "Payment" : activePlanDetails?.button}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentMethodModal;
