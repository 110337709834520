import React, { createContext, useContext, useState, ReactNode } from "react";

interface PlanDetail {
  id: string;
  amount: number;
  validity: number;
  type: number;
  is_trial: boolean;
}

interface SubscriptionContextProps {
  selectedPlan: PlanDetail | null;
  setSelectedPlan: (plan: PlanDetail | null) => void;
}

const SubscriptionContext = createContext<SubscriptionContextProps | undefined>(
  undefined
);

export const SubscriptionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<PlanDetail | null>(null);

  return (
    <SubscriptionContext.Provider value={{ selectedPlan, setSelectedPlan }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

// Custom Hook for easy access
export const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error(
      "useSubscription must be used within a SubscriptionProvider"
    );
  }
  return context;
};
