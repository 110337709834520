import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  StudentVerificationDetails,
  StudentVerificationSendOtp,
  StudentVerificationUpload,
  StudentVerificationVerifyOtp,
} from "../core/_requests";
import { toast } from "react-toastify";
import { useSubscription } from "../../Context/SubscriptionContext";
import Loader from "../../components/Loader";

const StudentVerification = () => {
  const navigate = useNavigate();
  const { selectedPlan } = useSubscription();
  const userId = localStorage.getItem("userId");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [date, setDate] = useState("");
  const [verified, setVerified] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [resendTimer, setResendTimer] = useState(0);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [image, setImage] = useState<any>("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const handleSendOTP = async () => {
    if (!email) {
      setError("Email is required.");
      return;
    }
    // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.edu$/;
    // if (!emailRegex.test(email)) {
    //   setError("Invalid email! Please enter a valid .edu email.");
    //   return;
    // }

    const data = {
      user_id: userId,
      email,
      amount: selectedPlan?.amount,
      type: selectedPlan?.type,
      validity: selectedPlan?.validity,
      is_trial: selectedPlan?.is_trial,
    };

    console.log("stu-data", data);

    setLoading(true);
    try {
      const newOtp = await StudentVerificationSendOtp(data);
      if (newOtp.status) {
        toast.success(newOtp.message);
        setSentOtp(true);
      }
      setError("");
      setResendTimer(30); // Start 30s timer
    } catch (error) {
      setError("Something went wrong! Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleVerifyOTP = async () => {
    const data = {
      user_id: userId,
      email,
      otp,
    };
    setLoading(true);
    try {
      const verifyOtp = await StudentVerificationVerifyOtp(data);
      if (verifyOtp.status) {
        setVerified(true);
        setError("");
      } else {
        setError("Invalid OTP! Please try again.");
      }
    } catch (error) {
      setError("Something went wrong! Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleErrorMessageClose = () => {
    setErrorMessage("");
    setPreviewImage(null);
    // Reset the file input value
    if (fileInputRef.current) {
      (fileInputRef.current as any).value = "";
    }
  };

  const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
  const allowedTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "application/pdf",
  ];

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    const files = e.target.files;
    if (!files || files.length === 0) return;

    const file = files[0];
    const fileType = file.type;
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      setErrorMessage(
        `Invalid file type. Allowed types are ${allowedExtensions
          .join(", ")
          .toUpperCase()}.`
      );
      setImage(null);
      return;
    }

    if (!allowedTypes.includes(fileType)) {
      setErrorMessage(
        `Invalid file type. Allowed types are ${allowedExtensions
          .join(", ")
          .toUpperCase()}.`
      );
      setImage(null);
      return;
    }

    if (file.size > 1024 * 1024) {
      setErrorMessage("File size should be less than 1MB.");
      setImage(null);
      return;
    }

    setErrorMessage(""); // Clear the error message
    setImage(file);
    // Read file if it's an image (PDF preview isn't needed)
    if (file.type.startsWith("image/")) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPreviewImage(reader.result as string); // Store in a separate state
        }
      };
      reader.readAsDataURL(file);
    }
  };

  console.log("error", error);

  const handleSubmit = async () => {
    if (!image) {
      setError("Please select a valid file.");
      return;
    }
    if (!date) {
      setError("Please select a valid date.");
      return;
    }
    const localDate = new Date(date); // Convert string to Date object
    const utcDate = new Date(
      localDate.getTime() - localDate.getTimezoneOffset() * 60000
    ).toISOString(); // Convert to UTC format

    const data = {
      user_id: userId,
      email,
      amount: selectedPlan?.amount,
      type: selectedPlan?.type,
      validity: selectedPlan?.validity,
      is_trial: selectedPlan?.is_trial,
      validity_date: utcDate,
    };

    const formData = new FormData();
    if (image) {
      formData.append("image", image);
    }
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value !== undefined ? String(value) : "");
    });

    setLoading(true);
    try {
      const { status } = await StudentVerificationUpload(formData);
      if (status) {
        setSuccessMessage(
          "Your student verification is in progress. You will be notified once approved. Meanwhile, you can explore other features of HomeChef360."
        );
        setError("");
      } else {
        setError("Please upload your student ID Proof.");
      }
    } catch (error) {
      setError("Something went wrong! Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  useEffect(() => {
    const fetchVerificationDetails = async () => {
      try {
        setDataLoaded(true);
        const { data } = await StudentVerificationDetails(userId);
        if (data) {
          setEmail(data.email || "");
          setOtp(data.otp || "");
          setVerified(data.is_otp_verify || false);
          if (data.image) {
            setSuccessMessage(
              "Your student verification is in progress. You will be notified once approved. Meanwhile, you can explore other features of HomeChef360."
            );
          }
        }
      } catch (error: any) {
        setDataLoaded(false);
        console.error(
          "An error occurred fetching verification details:",
          error.response?.data?.message || error.message
        );
      } finally {
        setDataLoaded(false);
      }
    };

    fetchVerificationDetails();
  }, []);

  if (dataLoaded) {
    return <Loader />;
  }
  console.log("iamge", image);
  return (
    <section className="container-fluid">
      <div className="overlay"></div>
      <div className="drawer small add-dish">
        <div style={{ height: "250px" }}>
          {successMessage ? (
            <div className="alert-wrap">
              <img
                className="ico mb-2"
                src="../../assets/images/alert-success-ico.svg"
                alt="success"
              />
              <h1 className="mb-4">Awesome!</h1>
              <p className="mid-gray mb-2 ps-3">
                <small>
                  {successMessage || "Plan activated successfully"}{" "}
                </small>
              </p>
              <button
                className="btn btn-cancel rounded-5 btn-outline-primary mt-3"
                onClick={() => navigate(`/info/${userId}`)}
              >
                Back to Home
              </button>
            </div>
          ) : (
            <>
              <h1 className="fw-bold mb-4 text-center">Student Verification</h1>
              <div
                style={{ minHeight: error && !verified ? "400px" : "350px" }}
              >
                {/* Error Message */}
                {error && (
                  <div className="alert alert-danger text-center">{error}</div>
                )}
                {!verified ? (
                  <>
                    {/* Email Input */}
                    <div className="row mb-3">
                      <label className="col-form-label">
                        Email
                      </label>
                      <div className="col-md-12">
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="example@university.edu"
                        />
                      </div>
                    </div>

                    {/* OTP Input - Shown after sending OTP */}
                    {sentOtp && (
                      <div className="row mb-3">
                        <label className="col-form-label">
                          OTP
                        </label>
                        <div className="col-md-12">
                          <input
                            type="text"
                            className="form-control"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                          />
                        </div>
                        <div className="text-end mt-2">
                          <button
                            className="btn btn-link text-primary p-0"
                            onClick={handleSendOTP}
                            disabled={resendTimer > 0}
                          >
                            {resendTimer > 0
                              ? `Resend OTP in ${resendTimer}s`
                              : "Resend OTP"}
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="col-12 mb-3 mt-3">
                      <label htmlFor="" className="form-label">
                        Upload Student ID
                      </label>
                      <div className="file-input mt-1">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          onChange={imageHandler}
                          ref={fileInputRef}
                        />
                        <label htmlFor="file">
                          <i className="material-symbols-outlined">upload</i>
                          <span> Upload Student ID </span>
                        </label>
                      </div>
                      <span className="file-info">
                        Supported file type (.jpg, .png, .jpeg,.pdf) Max file
                        size:1MB
                      </span>

                      {previewImage && (
                        <>
                          {" "}
                          <img src={previewImage} height="100" width="100" />
                          <button
                            className="btn p-0 close d-inline-flex "
                            onClick={handleErrorMessageClose}
                          >
                            <i className="material-symbols-outlined fs-18">
                              close
                            </i>
                          </button>
                        </>
                      )}
                      {errorMessage && (
                        <div
                          style={{ color: "red" }}
                          className="custom-error-messages"
                        >
                          {errorMessage}

                          <button
                            className="btn p-0 close d-inline-flex "
                            onClick={handleErrorMessageClose}
                          >
                            <i className="material-symbols-outlined fs-18">
                              close
                            </i>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="row mb-3">
                      <label className="col-form-label">ID Expiration Date</label>
                      <div className="col-md-12">
                        <input
                          type="date"
                          className="form-control"
                          value={date}
                          onChange={(e) => {
                            setDate(e.target.value);
                          }}
                          placeholder="Enter Validity Date"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <p style={{ marginTop: error && !verified ? "10px" : "0px" }}>
                <span className="text-danger">Note </span>: Please enter your
                college or school email ID. Personal or other organizational
                email IDs are not accepted.
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-cancel rounded-5 px-5 mt-2 mb-2 me-2"
                  onClick={() => navigate(`/info/${userId}`)}
                >
                  Cancel
                </button>
                {!verified ? (
                  sentOtp ? (
                    <button
                      type="button"
                      className="btn btn-primary rounded-5 px-5 mt-2 mb-2"
                      onClick={handleVerifyOTP}
                      disabled={loading}
                    >
                      {loading ? "Verifying..." : "Verify OTP"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary rounded-5 px-5 mt-2 mb-2"
                      onClick={handleSendOTP}
                      disabled={loading}
                    >
                      {loading ? "Sending OTP..." : "Send OTP"}
                    </button>
                  )
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary rounded-5 px-5 mt-2 mb-2"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Submit"}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default StudentVerification;
