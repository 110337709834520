import React from "react";
import "./Loader.css"; // Make sure to create this CSS file

const Loader = () => {
  return (
    <div className="loader-container">
      <img
        className="loading-logo"
        src="../assets/images/user-logo.png"
        alt="Loading..."
      />
     <span className="text-success">Loading...</span>
    </div>
  );
};

export default Loader;
