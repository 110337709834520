import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "../App";
import { ErrorPage } from "../features/ErrorPage";
import SubscriptionPage from "../modules/subscription/SubscriptionPage";
import PaymentSuccess from "../modules/subscription/files/Success";
import PaymentCancel from "../modules/subscription/files/Paymentcancel";
import SubscriptionForm from "../modules/subscription/files/TrialPlan";
import SalesPageUserPromoteKit from "../modules/subscription/files/SalesPageUserPromoteKit";
import UserPromoteKitSuccess from "../modules/subscription/files/UserPromoteKitSuccess";
import SalesPageChefsPromoteKit from "../modules/subscription/files/SalesPageChefsPromoteKit";
import ChefsPromotekitSuccess from "../modules/subscription/files/ChefsPromoteKitSuccess";
import PromoteKitScript from "../PromoteKitScript";
import ChooseSubscriptionPage from "../modules/subscription/files/ChooseSubscriptionPage";
import WelcomePage from "../modules/subscription/files/WelcomePage";
import CancelUserPK from "../modules/subscription/files/CancelUserPK";
import CancelChefPK from "../modules/subscription/files/CancelChefPK";
import StudentVerification from "../modules/subscription/files/StudentVerification";
import StudentSubscription from "../modules/subscription/files/studentSubscription";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Main App Layout */}
        <Route element={<App />}>
          {/* Public Routes */}
          <Route path="error/*" element={<ErrorPage />} />
          <Route path="info/:id" element={<SubscriptionPage />} />
          <Route path="info/" element={<SubscriptionPage />} />
          <Route path="payment-success/*" element={<PaymentSuccess />} />
          <Route path="payment-cancel/*" element={<PaymentCancel />} />
          <Route path="trial-plan/*" element={<SubscriptionForm />} />
          <Route path="student-verification" element={<StudentVerification/>} />
          <Route path="student-subscription/:id" element={<StudentSubscription/>} />
          <Route path="pk/subscription/*" element={<ChooseSubscriptionPage />} />
          <Route path="pk/*" element={<WelcomePage />} />
               {/* user promote kit */}
          <Route path="pk/subscription/users" element={
            <>
              <PromoteKitScript />
              <SalesPageUserPromoteKit />
            </>
          } />
          <Route path="pk/user-promotekit-success" element={
            <>
              <PromoteKitScript />
              <UserPromoteKitSuccess />
            </>
          } />
               <Route path="pk/user-promotekit-cancel" element={
            <>
              <PromoteKitScript />
              <CancelUserPK />
            </>
          } />
          {/* chef promote kit */}
          <Route path="pk/subscription/chefs" element={
            <>
              <PromoteKitScript />
              <SalesPageChefsPromoteKit />
            </>
          } />
          <Route path="pk/chefs-promotekit-success" element={
            <>
              <PromoteKitScript />
              <ChefsPromotekitSuccess />
            </>
          } />
      <Route path="pk/chefs-promotekit-cancel" element={
            <>
              <PromoteKitScript />
              <CancelChefPK />
            </>
          } />
          {/* Catch-All Route */}
          <Route path="/*" element={<WelcomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
