import React from "react";
import axios from "axios";
//BASE_URL
const API_URL = process.env.REACT_APP_API_URL;
const CHEF_API_URL = process.env.REACT_APP_CHEF_API_URL;

const getPlans = async (user_id: any) => {
  const response = await axios.post(`${API_URL}get-plan`, { user_id });
  console.log("getRatings-apii", response.data);
  return response.data;
};

const getCoupons = async (id: number) => {
  const response = await axios.get(`${API_URL}get-coupons/${id}`);
  console.log("getRatings-apii", response.data);
  return response.data;
};

const unsubscribePlan = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("UnsubscribePlan---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(`${API_URL}unsubscribe-plan`, data);
  return response.data;
};
const saveCardDetailsTostripe = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("saveCardDetailsTostripe---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(`${API_URL}save-card-stripe`, data);
  return response.data;
};
const createCheckoutSession = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("enableDisableStatus---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(`${API_URL}create-checkout-session`, data);
  return response.data;
};

const getPaymentStatus = async (
  session_id: string,
  subscribedActivePlanId: any
) => {
  console.log("Payment status API request:", {
    session_id,
    subscribedActivePlanId,
  });

  const response = await axios.post(`${API_URL}payment-status`, {
    session_id,
    subscribedActivePlanId,
  });

  console.log("Payment status API response:", response);
  return response.data;
};

const verifyStudentDetails = async (data: any) => {
  console.log("Payment status API request:", { data });
  const response = await axios.post(`${API_URL}verify-student`, data);
  console.log("Payment status API response:", response);
  return response.data;
};

const ProfileDetails = async (id: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("ProfileDetails apiiii ID", id);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(`${API_URL}view-user/${id}`);
  return response.data;
};

const createTrialSubscription = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("createTrialSubscription---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(`${API_URL}subscription-trial`, data);
  return response.data;
};

const createtSession = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("create-session---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(`${API_URL}create-session`, data);
  return response.data;
};
const getPlanForAffiliateUser = async () => {
  const response = await axios.post(`${API_URL}get-plan-affiliate`);
  console.log("getRatings-apii getPlanForAffiliate", response.data);
  return response.data;
};
const createPromoteKitCheckoutSessionUser = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("enableDisableStatus---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(
    `${API_URL}create-promotekit-session`,
    data
  );
  return response.data;
};

const getPlanForAffiliateChef = async () => {
  const response = await axios.post(`${CHEF_API_URL}get-plan-affiliate`);
  console.log("getRatings-apii getPlanForAffiliate", response.data);
  return response.data;
};
const createPromoteKitCheckoutSessionChef = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("enableDisableStatus---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(
    `${CHEF_API_URL}create-promotekit-session`,
    data
  );
  return response.data;
};

const FirstPromoterAffiliateAPI = async (data: any) => {
  console.log(
    "*********************************************************************"
  );
  console.log("enableDisableStatus---apii", data);
  console.log(
    "*********************************************************************"
  );
  const response = await axios.post(
    `${API_URL}create-firstpromoter-affiliate`,
    data
  );
  return response.data;
};

const getPaymentStatusChef = async (session_id: string) => {
  console.log("Payment status API request:", { session_id });

  const response = await axios.post(`${CHEF_API_URL}get-status-session`, {
    session_id,
  });

  console.log("Payment status API response:", response);
  return response.data;
};
const getPaymentStatusUser = async (session_id: string) => {
  console.log("Payment status API request:", { session_id });

  const response = await axios.post(`${API_URL}get-status-session`, {
    session_id,
  });

  console.log("Payment status API response:", response);
  return response.data;
};

const StudentVerificationSendOtp = async (data: any) => {
  console.log("Student Verification Send Otp data", data);

  const response = await axios.post(
    `${API_URL}student-verification-send-otp`,
    data
  );

  console.log("Student Verification Send Otp  API response:", response);
  return response.data;
};
const StudentVerificationVerifyOtp = async (data: any) => {
  console.log("Student Verification Verify Otp data", data);

  const response = await axios.post(
    `${API_URL}student-verification-verify-otp`,
    data
  );

  console.log("Student Verification Verify Otp  API response:", response);
  return response.data;
};

const StudentVerificationDetails = async (id: any) => {
  console.log("Student Verification details", id);

  const response = await axios.get(
    `${API_URL}student-verification-details/${id}`
  );

  console.log("Student Verification Details API response:", response);
  return response.data;
};
const StudentVerificationUpload = async (data: any) => {
  console.log("Student Verification upload data", data);

  const response = await axios.post(
    `${API_URL}student-verification-studentid`,
    data,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  console.log("Student Verification upload API response:", response);
  return response.data;
};
export {
  createtSession,
  getPlans,
  unsubscribePlan,
  getPaymentStatusChef,
  getPaymentStatusUser,
  createCheckoutSession,
  getPaymentStatus,
  ProfileDetails,
  verifyStudentDetails,
  saveCardDetailsTostripe,
  createTrialSubscription,
  FirstPromoterAffiliateAPI,
  getPlanForAffiliateUser,
  createPromoteKitCheckoutSessionUser,
  getPlanForAffiliateChef,
  createPromoteKitCheckoutSessionChef,
  getCoupons,
  StudentVerificationSendOtp,
  StudentVerificationVerifyOtp,
  StudentVerificationUpload,
  StudentVerificationDetails
};
