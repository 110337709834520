import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  createCheckoutSession,
  createTrialSubscription,
  ProfileDetails,
} from "../core/_requests";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../sidebar/Header";
import Footer from "../../sidebar/Footer";

// Load your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");

interface ApiResponse {
  success: boolean;
}

const TrialPlan = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedPlan, planId, userProfile } = location.state || {}; // Get the selected plan and other details
  // State for user inputs
  const [name, setName] = useState(userProfile?.name || "");
  const [email, setEmail] = useState(userProfile?.email || "");
  const [country, setCountry] = useState(
    userProfile?.addressbooks[0]?.country || ""
  );
  const [autoCharge, setAutoCharge] = useState(false);

  const user_id = localStorage.getItem("userId");

  console.log(
    "*********************************************************************"
  );
  console.log("selectedPlan -- trial plan page", selectedPlan);
  console.log("userProfile -- trial plan page", userProfile);
  console.log("selectedPlan, planId -- trial plan page", planId);
  console.log(
    "*********************************************************************"
  );

  if (!selectedPlan) {
    return <p>No plan data found.</p>;
  }
  const handleTrialSubscription = async () => {
    if (!stripe || !elements) {
      console.error("Stripe or Elements not loaded");
      return;
    }
    setIsProcessing(true);

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        alert("Card element not found. Please try again.");
        return;
      }

      // Create payment method in Stripe
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        alert(error.message);
        return;
      }

      if (!paymentMethod) {
        alert("Payment method creation failed. Please try again.");
        return;
      }

      const amount = selectedPlan.amount;
      const type = selectedPlan.type;
      const validity = selectedPlan.validity;
      const is_trial = selectedPlan.is_trial;
      const originalAmount = selectedPlan.originalAmount;
      const paymentType = 4;
      const data = {
        paymentMethodId: paymentMethod.id,
        planId,
        validity,
        amount,
        type,
        name,
        email,
        country,
        user_id,
        is_trial,
        paymentType,
        originalAmount,
        autoCharge, // Include the autoCharge option
      };

      const response = await createCheckoutSession(data);

      if (response.status === true) {
        toast.success(response.message);
        navigate(`/info/${user_id}`);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error during subscription:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <section className="container-fluid py-3">
        <div className="row align-items-center justify-content-between px-3 d-flex">
          <div className="col-12 col-md-auto d-flex justify-content-center justify-content-md-start mb-2 mb-md-0">
            <img
              className="img-fluid"
              src="../assets/images/user-logo.png"
              alt="Logo"
              style={{ height: "70px", width: "auto" }}
            />
          </div>
          <div className="col-12 col-md d-flex justify-content-center custom-padding">
            <h1 className="fw-bold text-success mb-0 fs-4 fs-md-3 ">
              Welcome to Plan Subscription
            </h1>
          </div>
        </div>
      </section>

      <div className="trial-plan-container mb-2">
        <h2 className="trial-plan-heading text-success fw-bold">
          Subscribe to Trial Plan
        </h2>
        <p className="text-success fw-bold text-center mb-2">
        Enjoy a 30-day free trial! After that, stay connected as your subscription renews automatically for just ${selectedPlan.amount} every {selectedPlan.validityCount}.
        </p>

        <p className="d-none">Plan ID: {planId}</p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleTrialSubscription();
          }}
          className="trial-plan-form"
        >
          <label className="label">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="form-control mb-2"
            placeholder="Enter your name"
            readOnly
          />
          <label className="label">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-control mb-2"
            placeholder="Enter your email"
            readOnly
          />
          <label className="label">Country</label>
          <input
            type="text"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
            className="form-control mb-2"
            placeholder="Enter your country"
            // readOnly
          />
          <div className="card-element-container">
            <label className="label">Card Details</label>
            <CardElement
              className="card-element"
              options={{ hidePostalCode: true }}
            />
          </div>
          <div className="auto-charge-container">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={autoCharge}
                onChange={() => setAutoCharge(!autoCharge)}
                className="checkbox-input"
              />
              Enable auto renewal
            </label>
          </div>
          <button
            type="submit"
            disabled={isProcessing || autoCharge === false}
            className={`submit-btn ${
              isProcessing || autoCharge === false
                ? "text-danger"
                : "text-white fw-bold"
            }`}
          >
            {isProcessing ? (
              <span className="text-success">Processing</span>
            ) : (
              "Activate Trial"
            )}
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

const TrialPlanWithElements = () => {
  return (
    <Elements stripe={stripePromise}>
      <TrialPlan />
    </Elements>
  );
};

export default TrialPlanWithElements;
