import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <>
      <div className="row mt-0 pt-0 pb-0 footer-style ">
        <div className="col-md-6">
          <p className="text-end small-font mt-3 mx-5 me-5">
            {new Date().getFullYear()} &copy; HomeChef360. All Rights Reserved.
          </p>
        </div>
         <div className="col-lg-1">
          <p className="text-center small-font mt-3">
          <img src="../assets/cards.png"  alt="cards"/>
          </p>
        </div>
        <div className="col-lg-5">
          <p className="text-center small-font mt-3">
            <span className="text-end mx-5">
              <Link
                to="/terms-conditions"
                className="footer-link cursorPointer me-3"
              >
                Terms & Conditions
              </Link>{" "}
              <Link
                to="/privacy-policy"
                className="footer-link cursorPointer me-3"
              >
                Privacy Policy
              </Link>{" "}
              <Link to="/refund-policy" className="footer-link cursorPointer">
                Refund Policy
              </Link>{" "}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
