import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  createCheckoutSession,
  getCoupons,
  ProfileDetails,
  StudentVerificationDetails,
} from "../core/_requests";
import { Link, useNavigate, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Modal } from "react-bootstrap";
import Footer from "../../sidebar/Footer";
import Header from "../../sidebar/Header";
import Loader from "../../components/Loader";

interface UserProfile {
  name: string;
  walletBalance: number;
  email?: string;
  profile?: string;
  contactNumber?: string;
}

const StudentSubscription = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
  );
  const planId = process.env.REACT_APP_SUBSCRIPTION_PLANID;
  console.log("process.env", process.env);
  const navigate = useNavigate();
  const { id } = useParams();
  const [paymentType, setPaymentType] = useState(1);
  const [walletBalance, setWalletBalance] = useState<any>();
  const [walletInputAmount, setWalletInputAmount] = useState<any>(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [selectedPlanOption, setSelectedPlanOption] = useState<any>({});
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [coupons, setCoupons] = useState<any>({});
  const [offerAmount, setOfferAmount] = useState<any>("");
  const [offerToday, setOfferToday] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const fetchCoupons = async () => {
    const response = await getCoupons(1);
    setCoupons(response.data[0]);
  };

  const fetchProfileDetails = async () => {
    try {
      const user_id = id;
      const res = await ProfileDetails(user_id);
      setUserProfile(res?.data);
      const balance = res.data?.walletBalance || 0;
      setWalletBalance(balance * 0.8); // Set to 20% of the returned balance
    } catch (err) {
      console.error("Error fetching profile details:", err);
      setWalletBalance(0); // Handle errors gracefully
    }
  };

  const convertValidityType = (validity: any, type: any) => {
    switch (type) {
      case 1:
        return `Year${validity > 1 ? "s" : ""}`;
      case 2:
        return `Month${validity > 1 ? "s" : ""}`;
      case 3:
        return `Day${validity > 1 ? "s" : ""}`;
      default:
        return `Annual`; // Default to days if type is unknown
    }
  };

  const fetchVerificationDetails = async () => {
    try {
      const { data } = await StudentVerificationDetails(id);
      const { amount, validity, type, is_trial } = data;
      if (data) {
        setSelectedPlanOption({
          id: planId,
          amount,
          validity,
          validityCount: convertValidityType(validity, type),
          type,
          isActive: false,
          is_trial,
          originalAmount: amount,
        });
      }
    } catch (error: any) {
      console.error(
        "An error occurred fetching verification details:",
        error.response?.data?.message || error.message
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoaded(true); // Data is fully loaded
        // Run all API calls in parallel
        await Promise.all([
          fetchVerificationDetails(),
          fetchProfileDetails(),
          fetchCoupons(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setDataLoaded(false); // Stop the loader once all data is loaded
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const now = new Date();

    // Convert current date to UTC
    const todayDate = new Date(now.toISOString());

    // Ensure startDate and endDate are also in UTC
    const startDate = coupons?.startDate ? new Date(coupons.startDate) : null;
    const endDate = coupons?.endDate ? new Date(coupons.endDate) : null;

    console.log("todayDate (UTC)", todayDate.toISOString());
    console.log("startDate (UTC)", startDate?.toISOString());
    console.log("endDate (UTC)", endDate?.toISOString());

    // Ensure all dates are valid before comparison
    if (startDate && endDate) {
      console.log(
        "todayDate >= startDate && todayDate <= endDate",
        todayDate >= startDate && todayDate <= endDate
      );

      if (todayDate >= startDate && todayDate <= endDate) {
        setOfferToday(true);
      }
    }
  }, [coupons]);

  useEffect(() => {
    if (offerToday && selectedPlanOption.type == 1) {
      if (coupons.couponType === 1) {
        // Percentage discount
        const discount =
          selectedPlanOption.amount -
          (selectedPlanOption.amount * coupons.amount) / 100;
        setOfferAmount(discount.toFixed(2));
        setWalletInputAmount(discount.toFixed(2));
      } else if (coupons.couponType === 2) {
        // Fixed amount discount
        const discount = selectedPlanOption.amount - coupons.amount;
        setOfferAmount(discount.toFixed(2));
        setWalletInputAmount(discount.toFixed(2));
      } else {
        // No discount
        setOfferAmount(null);
      }
    }
  }, [offerToday, selectedPlanOption]);

  useEffect(() => {
    const Amount = Number(
      Math.min(walletBalance, selectedPlanOption?.amount || 0).toFixed(2)
    );
    setMaxAmount(Amount);
    if (offerAmount) {
      setWalletInputAmount(offerAmount);
    } else {
      setWalletInputAmount(Amount);
    }
  }, [walletBalance, selectedPlanOption, offerAmount]);

  useEffect(() => {
    console.log("selectedPlanOption-redirect", selectedPlanOption);
    console.log("selectedPlanOption-planId", planId);
    if (selectedPlanOption.is_trial && planId && userProfile) {
      handleTrialSubscription();
    }
  }, [planId, selectedPlanOption, userProfile]);

  const offer = offerToday && offerAmount && selectedPlanOption?.type === 1;

  const handleSubscribe = async () => {
    const { amount, validity, type, is_trial } = selectedPlanOption;

    // Determine if the trial is being activated
    let effectivePaymentType = is_trial ? 4 : 2;
    let finalAmount = offer ? offerAmount : amount;
    let finalWalletInputAmount = 0;

    if (!is_trial) {
      if (paymentType === 1) {
        // Wallet payment selected
        if (walletInputAmount <= walletBalance && walletBalance != 0) {
          console.log(
            "walletInputAmount <= walletBalance && walletBalance != 0",
            walletInputAmount <= walletBalance && walletBalance != 0
          );

          if (walletInputAmount >= finalAmount) {
            console.log(
              "walletInputAmount >= finalAmount",
              walletInputAmount >= finalAmount
            );

            // Fully paid with wallet
            effectivePaymentType = 1;
            finalWalletInputAmount = finalAmount;
            // finalAmount = 0;
          } else {
            console.log("walletInputAmount >= finalAmount else 33");

            // Partial payment with wallet and card
            effectivePaymentType = 3;
            finalWalletInputAmount = walletInputAmount;
            finalAmount = finalAmount - walletInputAmount;
          }
        } else {
          // Invalid wallet input amount
          toast.error(
            "Entered wallet amount exceeds available wallet balance.",
            { autoClose: 5000 }
          );
          return;
        }
      } else if (paymentType === 2) {
        console.log("paymentType === 2", paymentType === 2);

        // Card payment selected
        effectivePaymentType = 2; // Fully paid with card
        finalWalletInputAmount = 0; // No wallet amount used
      }
    }

    try {
      setLoading(true);
      const sessionData = await createCheckoutSession({
        planId,
        originalAmount: amount,
        amount: finalAmount,
        user_id: id,
        type,
        validity,
        paymentType: effectivePaymentType,
        walletInputAmount: is_trial ? 0 : finalWalletInputAmount,
        is_trial: is_trial,
        ...(offer && { coupon_id: coupons?.primaryId }),
      });

      console.log("sessionData", sessionData);

      if (sessionData.status === true) {
        toast.success(sessionData.message, { autoClose: 5000 });
        if (effectivePaymentType === 1) {
          navigate(`/info/${id}`);
          return;
        }

        if (!is_trial) {
          const stripe = await stripePromise;
          if (stripe) {
            localStorage.setItem("sessionId", sessionData.id);
            const { error } = await stripe.redirectToCheckout({
              sessionId: sessionData.id,
            });
            if (error) {
              toast.error("Error processing payment. Please try again.", {
                autoClose: 5000,
              });
            }
          }
        } else {
          toast.error(sessionData.message, { autoClose: 5000 });
        }
      }
    } catch (error) {
      console.error("Error during subscription/payment process:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTrialSubscription = () => {
    if (!selectedPlanOption) {
      toast.error("Selected plan not found.");
      return;
    }
    // Navigate to the trial plan page and pass the selected plan data and planId via state
    navigate("/trial-plan", {
      state: { selectedPlan: selectedPlanOption, planId, userProfile },
    });
  };

  if (dataLoaded) {
    return <Loader />;
  }

  return (
    <>
      <div className="d-flex flex-column min-vh-100">
        {/* Header */}
        <section className="container-fluid py-3">
          <div className="row align-items-center justify-content-between px-3 d-flex">
            <div className="col-12 col-md-auto d-flex justify-content-center justify-content-md-start mb-2 mb-md-0">
              <img
                className="img-fluid"
                src="../assets/images/user-logo.png"
                alt="Logo"
                style={{ height: "70px", width: "auto" }}
              />
            </div>
            <div className="col-12 col-md d-flex justify-content-center custom-padding">
              <h1 className="fw-bold text-success mb-0 fs-4 fs-md-3 ">
                Welcome to Plan Subscription
              </h1>
            </div>
          </div>
        </section>

        {/* Payment Section */}
        <div className="container mb-2 d-flex justify-content-center w-100">
          <div className="card text-center p-4" style={{ maxWidth: "500px" }}>
            <h3 className="fs-5">Choose Your Payment Method</h3>

            {/* Payment Amount Section */}
            <p className="text-center text-danger mt-3 fs-6">
              Select a payment method to proceed: $
              {offer ? (
                <>
                  <span
                    style={{ textDecoration: "line-through", color: "gray" }}
                  >
                    {(selectedPlanOption?.amount || 0).toFixed(2)}
                  </span>{" "}
                  <span className="fw-bold text-success">${offerAmount}</span>{" "}
                </>
              ) : (
                (selectedPlanOption?.amount || 0).toFixed(2)
              )}
            </p>

            {/* Payment Methods - Row on Desktop, Column on Mobile */}
            <div className="row mt-3 d-flex flex-column flex-md-row">
              {/* Wallet Balance Option */}
              <div className="col-md-6 col-12 mb-3">
                <div className="form-check d-flex align-items-center">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="paymentType"
                    value="1"
                    checked={paymentType === 1}
                    onChange={() => setPaymentType(1)}
                  />
                  <div className="d-flex flex-column">
                    <span className="fw-bold">
                      <span
                        className="material-symbols-outlined me-2"
                        style={{
                          color: paymentType === 1 ? "#007bff" : "gray",
                        }}
                      >
                        account_balance_wallet
                      </span>
                      Wallet Balance
                    </span>
                    <small className="text-muted">
                      Use available balance ($
                      {(walletBalance ?? 0).toFixed(2)})
                    </small>
                  </div>
                </div>
                {paymentType === 1 && (
                  <div className="mt-3 mb-2">
                    <label className="small">Enter Amount to Deduct:</label>
                    <input
                      type="number"
                      className="form-control"
                      value={walletInputAmount}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        setWalletInputAmount(
                          value > maxAmount ? maxAmount : value
                        );
                      }}
                      placeholder={`Enter up to ${Math.min(
                        walletBalance,
                        selectedPlanOption?.amount || 0
                      ).toFixed(2)}`}
                    />
                  </div>
                )}
              </div>

              {/* Pay by Card Option */}
              <div className="col-md-6 col-12 mb-3">
                <div className="form-check d-flex align-items-center">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="paymentType"
                    value="2"
                    checked={paymentType === 2}
                    onChange={() => setPaymentType(2)}
                  />
                  <div className="d-flex flex-column">
                    <span className="fw-bold">
                      <span
                        className="material-symbols-outlined me-2"
                        style={{
                          color: paymentType === 2 ? "#007bff" : "gray",
                        }}
                      >
                        credit_card
                      </span>
                      Pay by Card
                    </span>
                    <small className="text-muted">
                      Pay using your credit/debit card
                    </small>
                  </div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="d-flex flex-column flex-md-row justify-content-center gap-2">
              <button className="btn btn-cancel w-100 rounded-5 px-3">
                Cancel
              </button>
              <button
                className="btn btn-primary w-100 rounded-5 px-3"
                onClick={handleSubscribe}
                disabled={
                  paymentType === 1
                    ? walletInputAmount <= 0 ||
                      walletInputAmount > (selectedPlanOption?.amount || 0) ||
                      walletInputAmount > walletBalance
                    : paymentType !== 2
                }
              >
                {loading ? "Processing..." : "Confirm Payment"}
              </button>
            </div>
          </div>
        </div>

        {/* Footer */}
        <footer className="py-4 mt-auto bg-light">
          <div className="container">
            <div className="row align-items-center">
              {/* Copyright */}
              <div className="col-lg-6 text-lg-end text-center">
                <p className="small mb-0 mx-lg-5">
                  {new Date().getFullYear()} &copy; HomeChef360. All Rights
                  Reserved.
                </p>
              </div>

              {/* Payment Cards Image */}
              <div className="col-lg-1 text-center my-3 my-lg-0">
                <img
                  src="../assets/cards.png"
                  alt="cards"
                  className="img-fluid"
                  style={{ maxHeight: "30px" }}
                />
              </div>

              {/* Policy Links */}
              <div className="col-lg-5 text-lg-start text-center">
                <p className="small mb-0 mx-lg-5">
                  <Link to="/terms-conditions" className="footer-link me-3">
                    Terms & Conditions
                  </Link>
                  <Link to="/privacy-policy" className="footer-link me-3">
                    Privacy Policy
                  </Link>
                  <Link to="/refund-policy" className="footer-link">
                    Refund Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default StudentSubscription;
